import React from "react";
import SparkSticker from "../images/spark_sticker.svg";

export default function Stamp({ ...rest }) {
	return (
		<div {...rest}>
			<SparkSticker />
		</div>
	);
}
